import MainHeader from '../../components/mainHeader/Index.vue'
import MainFooter from '../../components/mainFooter/Index.vue'
import Dict from '../../components/dict/Index.vue'
import { getJobById } from '@/api/job'
export default {
  components: { MainHeader, MainFooter, Dict },
  data(){
    return{
      jobId: '',
      job: {},
    }
  },
  mounted() {
    this.jobId = this.$route.query.jobId || ''
    if(this.jobId!='')
    {
      this.queryJob()
    }
  },
  methods: {
    toRouter(path){
      this.$router.push({name:path})
    },
    apply(applyLink){
      // this.$router.push({name:'jobPost'})
      if(applyLink)
        window.open(applyLink, '_blank')
      else
        this.$router.push({name:'jobPost'})
    },
    queryJob(){
      const that = this
      if(this.jobId != null){
        getJobById(this.jobId).then((resp) => {
          that.job = resp.data
        });
      }
    }
  }
};