import request from '@/utils/request'

export function savePostJob(data) {
  return request({
    url: '/job/postJob',
    method: 'post',
    data
  })
}

export function getJobById(id) {
  return request({
    url: '/job/getJobById/'+id,
    method: 'get'
  })
}

export function queryJobs(data) {
  return request({
    url: '/job/queryJobs',
    method: 'post',
    data
  })
}

export function getJobRoles() {
  return request({
    url: '/job/getJobRoles',
    method: 'get'
  })
}

export function getJobLocations() {
  return request({
    url: '/job/getJobLocations',
    method: 'get'
  })
}


